import React from 'react';
import '../App.css';
import './CallSection.css';

function CallSection() {

  return (
    <div className='call-container'>
      <div className='call-center'>
        <h2>Jaziel Landscaping promises exceptional service that will help your yard stand out. From general lawn maintenance to removing the trees taking up space in your yard.</h2>
        <h2>Give us a call today!</h2>
        <div className='number-text'><a href='tel:405-436-3079'>405-436-3079</a></div>
        <h2>Or send us an email below!</h2>
      </div>
    </div>
  );
}

export default CallSection;