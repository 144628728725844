import React from 'react';
import './Footer.css';

function Footer() {

  return (
    <div className='footer-wrapper'>
      <div className='footer-container'>
        <div className='grid'>
          <div className='grid-item'>
            <p className='header'>
              Services we offer
            </p>
            <div className='row-grid'>
              <a className='row-grid-item' href='/services#tree'>Tree</a>
              <a className='row-grid-item' href='/services#garden'>Garden</a>
              <a className='row-grid-item' href='/services#land'>Landscaping</a>
              <a className='row-grid-item' href='/services#lawn'>Lawn</a>
            </div>
          </div>
          <div className='grid-item'>
            <p className='header'>
              Contact us
            </p>
            <div className='row-grid'>
              <a className='row-grid-item' href='tel:405-436-3079'>405-436-3079</a>
              <a className='row-grid-item' href='mailto:jaziellandscaping@gmail.com?subject=Quote%20Interest'>jaziellandscaping@gmail.com</a>
            </div>
          </div>
          <div className='grid-item'>
            <p className='header'>Hours</p>
            <div className='row-grid'>
              <p className='row-grid-item-2'>Mon 7AM - 5PM</p>
              <p className='row-grid-item-2'>Tues 7AM - 5PM</p>
              <p className='row-grid-item-2'>Wed 7AM - 5PM</p>
              <p className='row-grid-item-2'>Thu 7AM - 5PM</p>
              <p className='row-grid-item-2'>Fri 7AM - 5PM</p>
              <p className='row-grid-item-2'>Sat 9AM - 3PM</p>
              <p className='row-grid-item-2'>Sun 12PM - 6PM</p>
            </div>
          </div>
          <div className='grid-item'>
              <p className='header'>
                About us
              </p>
              <p className='subtitle'>
                Jaziel Landscaping, LLC. is a landscaping company located in Oklahoma City, Oklahoma. 
              </p>
              <span>
                <i className='fas fa-instagram info-icons-white brands'></i> <i className='fas fa-facebook info-icons-white brands'></i>
              </span>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <span>
          &copy; 2021 Jaziel Landscaping, LLC. All rights reserved. | Images from <a href="https://www.unsplash.com/" title="Unsplash">Unsplash</a> | Website built by <a href="https://www.linebreak.tech/" title="Linebreak">Linebreak Software</a>
        </span>
      </div>
    </div>
  );
}

export default Footer;