import React, { useState } from 'react';
import '../App.css';
import './AboutSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FullLogo from '../images/logos/full_logo.png';
import { db } from '../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(250,250,250)',
    margin: 'auto',
  },
  icon: {
    marginTop: 24,
    margin: 'auto',
    width: 200,
  },
  title: {
    fontSize: 18,
    color: '#232323',
  },
  subtitle: {
    marginTop: 8,
    fontSize: 16,
    fontFamily: 'Open Sans, sans-serif',
    color: '#232323',
  },
  text: {
    [theme.breakpoints.down("sm")]:{
      textAlign: 'center',
    },
    fontSize: 20,
    fontFamily: 'Open Sans, sans-serif',
    color: '#232323',
    paddingLeft: 30,
    paddingRight: 30,
  },
  color: {
    color: 'rgb(63,109,63)',
  },
  pos: {
    [theme.breakpoints.up("lg")]:{
      paddingRight: 20,
    },
    marginTop: 8,
    marginBottom: 8,
  },
}));

function ContactForm() {

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('contacts').add({
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      message: message,
    })
    .then(() => {
    })
    .catch((error) => {
    });

    setName("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Card className={classes.root} elevation={0}>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" >
                Want to get a quote?
              </Typography>
              <Typography className={classes.subtitle} color="textSecondary" >
                Send us an email.
              </Typography>
              <TextField required id="standard-required" label="Full Name" placeholder="Full Name" fullWidth className={classes.pos} value={name} onChange={(e) => setName(e.target.value)}/>
              <br />
              <TextField required id="standard-required" label="Phone Number" placeholder="Phone Number" fullWidth className={classes.pos} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
              <br />
              <TextField required id="standard-required" label="Email" placeholder="Email" fullWidth className={classes.pos} value={email} onChange={(e) => setEmail(e.target.value)}/>
              <br />
              <TextField id="standard-multiline-static" label="Description of Work Wanted" multiline rows={2} fullWidth className={classes.pos} value={message} onChange={(e) => setMessage(e.target.value)}/>
            </CardContent>
            <CardActions>
              <Button size="small" className={classes.color} type='submit' style={{ width: '100%', justifyContent: 'flex-end' }}>
                Submit <i className='fas fa-chevron-right link-chevron'/>
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </React.Fragment>
  );
}

function AboutSection() {

  const classes = useStyles();

  function ButtonRow() {
    return (
      <React.Fragment>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <ContactForm />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Typography className={classes.text} component="p">
            Jaziel Landscaping is a family owned and operated landscaping business located in Oklahoma City, Oklahoma. We serve Oklahoma City and all nearby cities. At Jaziel Landscaping we aim to deliver the best landscaping services possible.
          </Typography>
          <CardMedia 
            component="img"
            className={classes.icon}
            alt="Contemplative Reptile"
            image={FullLogo}
            title="Contemplative Reptile"
          />
        </Grid>
      </React.Fragment>
    );
  }

  function GridRow() {
    return (
      <Container className='about-margin-top' maxWidth='xl'>
          <Grid container alignItems='center' justify='center'>
            <Grid container alignItems='center' justify='center' item xs={12} spacing={2}>
              <ButtonRow />
            </Grid>
          </Grid>
      </Container>
    );
}

  return (
    <div className='about-container'>
      <h1>About Jaziel Landscaping</h1>
      <div className='outside-margins-about'><GridRow /></div>
    </div>
  );
}

export default AboutSection;