import React from 'react';
import '../App.css';
import './GallerySection.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Photo0 from '../images/projectphotos/photo0.jpg';
import Photo1 from '../images/projectphotos/photo1.jpg';
import Photo2 from '../images/projectphotos/photo2.jpg';
import Photo3 from '../images/projectphotos/photo3.jpg';
import Photo4 from '../images/projectphotos/photo4.jpg';
import Photo5 from '../images/projectphotos/photo5.jpg';
import Photo6 from '../images/projectphotos/photo6.JPG';
import Photo7 from '../images/projectphotos/photo7.jpg';
import Photo8 from '../images/projectphotos/photo8.jpg';
import Photo9 from '../images/projectphotos/photo9.jpg';
import Photo10 from '../images/projectphotos/photo10.jpg';
import Photo11 from '../images/projectphotos/photo11.jpg';
import Photo13 from '../images/projectphotos/photo13.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    margin: 'auto',
  },
  color: {
    color: 'rgb(63,109,63)',
  },
  icon: {
    [theme.breakpoints.down("md")]:{
      height: 300,
    },
    height: 400,
  },
}));

const photos = [
  {
    photo: Photo2,
    text: 'Tree Photo'
  },
  {
    photo: Photo3,
    text: 'Lawn Photo'
  },
  {
    photo: Photo4,
    text: 'Tree Photo'
  },
  {
    photo: Photo5,
    text: 'Lawn Photo'
  },
  {
    photo: Photo6,
    text: 'Tree Photo'
  },
  {
    photo: Photo7,
    text: 'Lawn Photo'
  },
  {
    photo: Photo8,
    text: 'Tree Photo'
  },
  {
    photo: Photo9,
    text: 'Lawn Photo'
  },
  {
    photo: Photo10,
    text: 'Tree Photo'
  },
  {
    photo: Photo11,
    text: 'Lawn Photo'
  },
  {
    photo: Photo13,
    text: 'Tree Photo'
  },
  {
    photo: Photo1,
    text: 'Tree Photo'
  },
  {
    photo: Photo0,
    text: 'Lawn Photo'
  },
];

function GallerySection() {
  const classes = useStyles();

    function ButtonRow() {
      return (
        <React.Fragment>
          {photos.map(e =>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    className={classes.icon}
                    alt={e.text}
                    image={e.photo}
                    title={e.text}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </React.Fragment>
      );
    }

    function GridRow() {
        return (
          <Container maxWidth='xl'>
              <Grid container alignItems='center' justify='center'>
                <Grid container alignItems='center' justify='center' item xs={12} spacing={4}>
                  <ButtonRow />
                </Grid>
              </Grid>
          </Container>
        );
    }

  return (
    <div className='gallery-container'>
      <div className='outside-margins-gallery'><GridRow /></div>
    </div>
  );
}

export default GallerySection;