import React from 'react';
import '../App.css';
import './ServicePageSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tree from '../images/trees.jpg';
import Garden from '../images/gardening.jpg';
import Lawn from '../images/grasscut.jpg';
import Land from '../images/landscaping.jpg';


const useStyles = makeStyles((theme) => ({
  image: {
    height: 200,
    borderRadius: 4,
  },
  text: {
    [theme.breakpoints.down("md")]:{
      paddingLeft: 20,
      textAlign: 'start',
    },
    [theme.breakpoints.down("sm")]:{
      paddingLeft: 0,
      textAlign: 'center',
    },
    fontSize: 30,
    color: 'rgb(63,109,63)',
    paddingLeft: 80,
    paddingBottom: 16,
    textAlign: 'start',
  },
  subtext: {
    [theme.breakpoints.down("md")]:{
      paddingLeft: 20,
      textAlign: 'start',
    },
    [theme.breakpoints.down("sm")]:{
      paddingLeft: 0,
      textAlign: 'center',
    },
    paddingLeft: 80,
    textAlign: 'start'
  },
  padding: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  paper: {
    backgroundColor: 'rgb(250,250,250)',
    padding: theme.spacing(1),
    color: '#232323',
    [theme.breakpoints.down("md")]:{
      paddingLeft: 20,
      textAlign: 'start',
    },
    [theme.breakpoints.down("sm")]:{
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paddingLeft: 80,
    textAlign: 'start',
    fontSize: 16,
  },
  color: {
    marginTop: 24,
    marginBottom: 24,
    color: 'rgb(63,109,63)',
  },
}));

function ServicePageSection() {
  const classes = useStyles();

  function TreeFormRow() {
    return (
      <React.Fragment>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Tree Planting</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Tree Trimming</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Tree Removal</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Stump Grinding</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  function TreeRow() {
    return (
      <React.Fragment>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <div id='tree'>
            <CardMedia
              component="img"
              className={classes.image}
              alt="Tree Services"
              image={Tree}
              title="Tree Services"
            />
          </div>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography variant="h5" className={classes.text} component="h2">
            Tree Service
          </Typography>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <TreeFormRow />
            </Grid>
          </Grid>
          <Button size="small" className={classes.color} href='/contact' style={{ width: '100%', justifyContent: 'flex-end' }}>
            Request an Estimate <i className='fas fa-chevron-right link-chevron'/>
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function GardenFormRow() {
    return (
      <React.Fragment>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Edging</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Leaf Blowing</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Flower Beds</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  function GardenRow() {
    return (
      <React.Fragment>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <div id='garden'>
            <CardMedia
              component="img"
              className={classes.image}
              alt="Garden Maintenance"
              image={Garden}
              title="Garden Maintenance"
            />
          </div>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography variant="h5" className={classes.text} component="h2">
            Garden Maintenance
          </Typography>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <GardenFormRow />
            </Grid>
          </Grid>
          <Button size="small" className={classes.color} href='/contact' style={{ width: '100%', justifyContent: 'flex-end' }}>
            Request an Estimate <i className='fas fa-chevron-right link-chevron'/>
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function LandFormRow() {
    return (
      <React.Fragment>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Lot Clearing</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Wood Fencing</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Drainage</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Christmas Lights</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  function LandscapingRow() {
    return (
      <React.Fragment>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <div id='land'>
            <CardMedia
              component="img"
              className={classes.image}
              alt="Landscaping"
              image={Land}
              title="Landscaping"
            />
          </div>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography variant="h5" className={classes.text} component="h2">
            Landscaping
          </Typography>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <LandFormRow />
            </Grid>
          </Grid>
          <Button size="small" className={classes.color} href='/contact' style={{ width: '100%', justifyContent: 'flex-end' }}>
            Request an Estimate <i className='fas fa-chevron-right link-chevron'/>
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function LawnFormRow() {
    return (
      <React.Fragment>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Mowing</Paper>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Paper className={classes.paper} elevation={0}>Weed Wacking</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  function LawnRow() {
    return (
      <React.Fragment>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <div id ='lawn'>
            <CardMedia
              component="img"
              className={classes.image}
              alt="Lawn"
              image={Lawn}
              title="Lawn"
            />
          </div>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography variant="h5" className={classes.text} component="h2">
            Lawn Service
          </Typography>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <LawnFormRow />
            </Grid>
          </Grid>
          <Button size="small" className={classes.color} href='/contact' style={{ width: '100%', justifyContent: 'flex-end' }}>
            Request an Estimate <i className='fas fa-chevron-right link-chevron'/>
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function GridRow() {
    return (
      <Container maxWidth='xl'>
        <Grid container alignItems='center' justify='center' spacing={2}>
          <TreeRow />
          <GardenRow />
          <LandscapingRow />
          <LawnRow />
        </Grid>
      </Container>
    );
  }

  return (
    <div className='servicepage-container'>
      <h1>Services We Offer</h1>
      <div className='outside-margins-servicepage'><GridRow /></div>
    </div>
  );
}

export default ServicePageSection;