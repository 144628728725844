import React from 'react';
import '../App.css';
import './ServiceSection.css';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tree from '../images/trees.jpg';
import Garden from '../images/gardening.jpg';
import Lawn from '../images/grasscut.jpg';
import Land from '../images/landscaping.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(250,250,250)',
    width: 280, 
    borderRadius: 2,
    margin: 'auto',
  },
  color: {
    color: 'rgb(63,109,63)',
  },
  image: {
    width: 280,
    height: 220,
  },
  text: {
    paddingTop: 24,
    paddingBottom: 24,
    textAlign: 'center'
  },
  pos: {
    marginBottom: 8,
  },
}));

function ServiceSection() {
  const classes = useStyles();

    function ButtonRow() {
      return (
        <React.Fragment>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Card className={classes.root} elevation={0}>
                <CardMedia
                  component="img"
                  className={classes.image}
                  alt="Tree Services"
                  image={Tree}
                  title="Tree Services"
                />
                <CardContent>
                  <Typography className={classes.text} variant="h5" component="h2">
                    Tree Services
                  </Typography>
                </CardContent>
                <CardActions>
                <Button size="small" className={classes.color} href='/services#tree' style={{ width: '100%', justifyContent: 'flex-end' }}>
                  Learn More <i className='fas fa-chevron-right link-chevron'/>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Card className={classes.root} elevation={0}>
                <CardMedia
                  component="img"
                  className={classes.image}
                  alt="Garden Maintenance"
                  image={Garden}
                  title="Garden Maintenance"
                />
                <CardContent>
                  <Typography variant="h5" className={classes.text} component="h2">
                    Garden Maintenance
                  </Typography>
                </CardContent>
              <CardActions>
                <Button size="small" className={classes.color} href='/services#garden' style={{ width: '100%', justifyContent: 'flex-end' }}>
                  Learn More <i className='fas fa-chevron-right link-chevron'/>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Card className={classes.root} elevation={0}>
                <CardMedia
                  component="img"
                  className={classes.image}
                  alt="Landscaping"
                  image={Land}
                  title="Landscaping"
                />
                <CardContent>
                  <Typography variant="h5" className={classes.text} component="h2">
                    Landscaping
                  </Typography>
                </CardContent>
              <CardActions>
                <Button size="small" className={classes.color} href='/services#land' style={{ width: '100%', justifyContent: 'flex-end' }}>
                  Learn More <i className='fas fa-chevron-right link-chevron'/>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Card className={classes.root} elevation={0}>
                <CardMedia
                  component="img"
                  className={classes.image}
                  alt="Lawn Services"
                  image={Lawn}
                  title="Lawn Services"
                />
                <CardContent>
                  <Typography variant="h5" className={classes.text} component="h2">
                    Lawn Services
                  </Typography>
                </CardContent>
              <CardActions>
                <Button size="small" className={classes.color} href='/services#lawn' style={{ width: '100%', justifyContent: 'flex-end' }}>
                  Learn More <i className='fas fa-chevron-right link-chevron'/>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </React.Fragment>
      );
    }

    function GridRow() {
        return (
          <Container className='margin-top-service' maxWidth='xl'>
              <Grid container alignItems='center' justify='center'>
                <Grid container alignItems='center' justify='center' item xs={12} spacing={2}>
                  <ButtonRow />
                </Grid>
              </Grid>
          </Container>
        );
    }

  return (
    <div className='service-container'>
      <h1>Services We Offer</h1>
      <Link to='/services' className='link'>
        All Services <i className='fas fa-chevron-right link-chevron'></i>
      </Link>
      <div className='outside-margins-service'><GridRow /></div>
    </div>
  );
}

export default ServiceSection;