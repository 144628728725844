import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ContactFormSection from '../ContactForm';

function Contact() {
  return (
    <>
      <ContactFormSection />
      <Footer />
    </>
  );
}

export default Contact;