import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Gallery from '../GallerySection';

function Contact() {
  return (
    <>
      <Gallery />
      <Footer />
    </>
  );
}

export default Contact;