import React from 'react';
import '../../App.css';
import ServicePageSection from '../ServicePageSection';
import Footer from '../Footer';

function Contact() {
  return (
    <>
      <ServicePageSection />
      <Footer />
    </>
  );
}

export default Contact;