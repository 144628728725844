import React, { useState } from 'react';
import '../App.css';
import './ContactForm.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FullLogo from '../images/logos/full_logo_white.png';
import { db } from '../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(250,250,250)',
    margin: 'auto',
  },
  icon: {
    marginTop: 24,
    margin: 'auto',
    width: 240,
  },
  title: {
    fontSize: 18,
    color: '#232323',
  },
  subtitle: {
    marginTop: 8,
    fontSize: 16,
    fontFamily: 'Open Sans, sans-serif',
    color: '#232323',
  },
  text: {
    textAlign: 'center',
    fontSize: 30,
    fontFamily: 'Open Sans, sans-serif',
    color: 'rgb(220,220,220)',
  },
  color: {
    color: 'rgb(63,109,63)',
  },
  pos: {
    marginTop: 8,
    marginBottom: 8,
  },
  link: {
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 32,
    fontFamily: 'Open Sans, sans-serif',
    paddingTop: 8,
    color: 'rgb(220,220,220)',
  }
}));

function ContactForm() {

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('contacts').add({
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      message: message,
    })
    .then(() => {
    })
    .catch((error) => {
    });

    setName("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Card className={classes.root} elevation={0}>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" >
                Want to get a quote?
              </Typography>
              <Typography className={classes.subtitle} color="textSecondary" >
                Send us an email.
              </Typography>
              <TextField required id="standard-required" label="Full Name" placeholder="Full Name" fullWidth className={classes.pos} value={name} onChange={(e) => setName(e.target.value)}/>
              <br />
              <TextField required id="standard-required" label="Phone Number" placeholder="Phone Number" fullWidth className={classes.pos} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
              <br />
              <TextField required id="standard-required" label="Email" placeholder="Email" fullWidth className={classes.pos} value={email} onChange={(e) => setEmail(e.target.value)}/>
              <br />
              <TextField id="standard-multiline-static" label="Description of Work Wanted" multiline rows={2} fullWidth className={classes.pos} value={message} onChange={(e) => setMessage(e.target.value)}/>
            </CardContent>
            <CardActions>
              <Button size="small" className={classes.color} type='submit' style={{ width: '100%', justifyContent: 'flex-end' }}>
                Submit <i className='fas fa-chevron-right link-chevron'/>
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </React.Fragment>
  );
}

function ContactSection() {

  const classes = useStyles();

  function ButtonRow() {
    return (
      <React.Fragment>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Typography className={classes.text} component="p">
            Let's get in touch!
          </Typography>
          <div className={classes.link}><a className={classes.link} href='tel:405-436-3079'>405-436-3079</a></div>
          <CardMedia 
            component="img"
            className={classes.icon}
            alt="Contemplative Reptile"
            image={FullLogo}
            title="Contemplative Reptile"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <ContactForm />
        </Grid>
      </React.Fragment>
    );
  }

  function GridRow() {
    return (
      <Container className='contact-form-margin-top' maxWidth='xl'>
          <Grid container alignItems='center' justify='center'>
            <Grid container alignItems='center' justify='center' item xs={12} spacing={2}>
              <ButtonRow />
            </Grid>
          </Grid>
      </Container>
    );
}

  return (
    <div className='contact-form-container'>
      <div className='outside-margins-contact-form'><GridRow /></div>
    </div>
  );
}

export default ContactSection;