import firebase from 'firebase';

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCcfNy2AbLVsYOo5gPX4WYapP9U4mRFNbg",
  authDomain: "jaziel-landscaping.firebaseapp.com",
  projectId: "jaziel-landscaping",
  storageBucket: "jaziel-landscaping.appspot.com",
  messagingSenderId: "214420187827",
  appId: "1:214420187827:web:df0792108a3405e46d5ec6",
  measurementId: "G-5TGC8JCKP9"
});

var db = firebaseApp.firestore();

export { db };