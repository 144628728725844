import React from 'react';
import '../App.css';
import './HeroSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

//icons: https://www.flaticon.com/packs/grounds-keeping-3

const useStyles = makeStyles((theme) => ({
  margin: {
    [theme.breakpoints.up("xs")]:{
      width: 144,
      height: 48,
    },
    [theme.breakpoints.up("sm")]:{
      width: 144,
      height: 48,
    }, 
    [theme.breakpoints.up("md")]:{
      width: 192,
      height: 48,
    },
    [theme.breakpoints.up("lg")]:{
      width: 216,
      height: 56,
    }, 
    margin: theme.spacing(2),
    color: '#fff',
    borderColor: '#fff',
  },
}));

function HeroSection() {
    const classes = useStyles();

    return (
        <div className='hero-container outside-margins'>
            <h1>Creating Beautiful Landscapes</h1>
            <p>At Jaziel Landscaping we pride ourselves in providing you the best service possible.</p>
            <div className='button-padding'>
              <Button variant="outlined" size="large" className={classes.margin} href='/services'>
                Services
              </Button>
              <Button variant="outlined" size="large" className={classes.margin} href='/contact'>
                Request an Estimate
              </Button>
            </div>
        </div>
  );
}

export default HeroSection;