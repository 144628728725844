import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../images/logos/small-logo.png';

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <div className='nav-split-one'><Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src={logo} alt='LOGO' height='50px' className='logo-img'></img> <div className='logo-text'>Jaziel Landscaping</div>
                    </Link></div>
                    <div className='nav-split-two'> <div className='menu-icon' onClick = {handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                                Services <i className='fas fa-chevron-down'></i>
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
                                Projects <i className='fas fa-chevron-down'></i>
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                Contact <i className='fas fa-chevron-down'></i>
                            </Link>
                        </li>
                    </ul></div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;