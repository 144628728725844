import React, {useState} from 'react';
import './Infobar.css';

function Infobar() {
    const [infoClick, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav className='infobar'>
                <div className='infobar-container'>
                    <div className='infobar-logo' onClick={closeMobileMenu}>
                        Welcome to Jaziel Landscaping
                    </div>
                    <ul className={infoClick ? 'info-menu active' : 'info-menu'}>
                        <li className='info-item'>
                            <div className='info-links' onClick={closeMobileMenu}>
                                <i className='fas fa-home info-icons'></i>Oklahoma City, Oklahoma, USA
                            </div>
                        </li>
                        <li className='info-item pad-right'>
                            <div className='info-links' onClick={closeMobileMenu}>
                                <i className='fas fa-phone-alt info-icons'></i>Toll Free: 405-436-3079
                            </div>
                        </li>
                        <i className='fas fa-instagram info-icons-white brands'></i>
                        <i className='fas fa-facebook info-icons-white brands'></i>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Infobar;